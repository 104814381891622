import React from "react";
import Layout from "../components/layout";

const Events = (props) => {
  return (
    <Layout location={props.location}>
      <div className="columns" style={{ marginTop: "4rem" }}>
        <div className="column is-full" style={{ marginBottom: "2rem" }}>
          <h1 className="title is-1">Past Events</h1>
        </div>
      </div>

      <Event
        name="#47"
        text="Join us for the Online Talks and Networking for Designers #47 Meetup co-organized with Adobe XD.
We´ll kick at 7 pm, panel starts at 7:15 pm. We will have a lucky draw for 1 Adobe Creative Cloud license.
The meetup will take place on Zoom. You can join either via the app (recommended) or your browser (does not work with Safari). For all that RSVPd, you should be able to see the Zoom link on this page."
        photo="https://images.unsplash.com/photo-1561442101-ae4195e55eb1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
      />

      <Event
        name="#46"
        text="Join us for the Online Talks and Networking for Designers #47 Meetup co-organized with Adobe XD.
We´ll kick at 7 pm, panel starts at 7:15 pm. We will have a lucky draw for 1 Adobe Creative Cloud license.
The meetup will take place on Zoom. You can join either via the app (recommended) or your browser (does not work with Safari). For all that RSVPd, you should be able to see the Zoom link on this page."
        photo="https://images.unsplash.com/photo-1587906697341-bfbde76785c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1526&q=80"
      />

      <Event
        name="#45"
        text="Join us for the Online Talks and Networking for Designers #47 Meetup co-organized with Adobe XD.
We´ll kick at 7 pm, panel starts at 7:15 pm. We will have a lucky draw for 1 Adobe Creative Cloud license.
The meetup will take place on Zoom. You can join either via the app (recommended) or your browser (does not work with Safari). For all that RSVPd, you should be able to see the Zoom link on this page."
        photo="https://images.unsplash.com/photo-1561442101-ae4195e55eb1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
      />
    </Layout>
  );
};

const Event = ({ name, text, photo }) => {
  return (
    <div className="columns" style={{ marginBottom: "3rem" }}>
      <div className="column is-half">
        <img src={photo} alt={name} />
      </div>

      <div className="column is-half">
        <h2 className="subtitle is-3">{name}</h2>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Events;
